// extracted by mini-css-extract-plugin
export var caseContactFormSection = "bP_kC";
export var caseStudyBackground__lineColor = "bP_kz";
export var caseStudyHead__imageWrapper = "bP_lC";
export var caseStudyProjectsSection = "bP_kD";
export var caseStudyQuote__bgRing = "bP_kx";
export var caseStudyQuote__bottomVideo = "bP_kR";
export var caseStudyQuote__light = "bP_lv";
export var caseStudyQuote__lightLast = "bP_lD";
export var caseStudyVideo__ring = "bP_kN";
export var caseStudy__bgDark = "bP_ks";
export var caseStudy__bgLight = "bP_kr";
export var caseStudy__bgLightReverse = "bP_k8";